import React from "react"

import "./MusingCard.css"

const MusingCard = (props) => {

    const renderDetail = (key, value) => {
        return(
            <div className={"MusingCardDetail"}>
                <p>{key}</p>
                <p className={"MusingCardDetailBold"}>{value}</p>
            </div>
        )
    }

    return(
        <div className={"MusingCard"}>
            <div className={"MusingCardTitle"}>
                <p>{props.title}</p>
            </div>
            {props.author ? renderDetail("Írta:", props.author) : null }
            {props.translator ? renderDetail("Fordította:", props.translator) : null }
            <a href={props.path}>
                <div className={"MusingCardBtn"}>
                    <p className={"MusingCardBtnTitle"}>elolvasom</p>
                </div>
            </a>      
        </div>
    )
}

export default MusingCard